import React from 'react';
import { Fade } from 'src/animations';
import { Section } from 'src/component';
import { content, images, person } from 'src/config';
import { htmlTag } from 'src/types';
import { HeavyText, Image, PrimaryButton, PrimaryLink, Text } from 'src/widget';
import classes from './HomeHero.module.scss';

const UnmemoHomeHero: React.FC = () => {
  const { text1, text2, primaryCTA, primaryLink } = content.home.hero;

  return (
    <Section isFullHeight>
      <div className="container">
        <Fade>
          <div className={classes.name}>
            <h1>{person.name}</h1> <span>| {person.designation}</span>
          </div>
        </Fade>

        <div className={classes.designation}>
          <HeavyText tag={htmlTag.h2} lines={'two-line'}>
            {person.speciality}
          </HeavyText>
        </div>

        <div className={classes.footer}>
          <div className="row">
            <div className="col-lg-4">
              <Fade>
                <div className={classes.image}>
                  <Image src={images.ProfileOutline} alt="" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <Fade>
                    <div className={classes.left}>
                      <Text>{text1}</Text>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade>
                    <div className={classes.right}>
                      <Text>{text2}</Text>
                    </div>
                  </Fade>
                </div>
              </div>
              <Fade>
                <div className={classes.btn}>
                  <PrimaryButton
                    text={primaryCTA}
                    href={`https://calendly.com/ishahasan9993/30min`}
                    icon={{ name: 'contact' }}
                    className={classes.primary}
                    target="_blank"
                  />
                  <PrimaryLink
                    text={primaryLink}
                    href={'/ishahasan_resume.pdf'}
                    useLink={false}
                    target="_blank"
                  />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const HomeHero = React.memo(UnmemoHomeHero);
